import React, { ReactElement, FC, useEffect } from "react";

declare global {
    interface Window {
        Trustpilot?: {
            loadFromElement: (element: HTMLElement, isMobile: boolean) => void;
        };
    }
}
const TrustpilotDesktop: FC<any> = (): ReactElement => {
    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot && ref.current) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, [ref]);
    return (
        <>
            <div className="flex flex-row mt-auto items-start justify-start pt-4 w-full">
                <div ref={ref} className="trustpilot-widget w-full" data-locale="en-US" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5e1f84631d20eb000130bc73" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="5" data-review-languages="en">
                    <a href="https://www.trustpilot.com/review/carclarity.com.au" target="_blank" rel="noopener noreferrer">Trustpilot</a>
                </div>
            </div>
        </>
    )
}

export default TrustpilotDesktop